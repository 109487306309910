import React from 'react'
// Types
import { SectionBaseProps, SectionBlog } from '../../../types/sections'
import { ArticlePreviewWithAuthorAndCategoriesProps } from '../../../types/blog'
// Utils
import { lastBlogPostsQuery } from './Blog.utils'
// Components
import { Grid, Row, Col } from 'react-styled-flexboxgrid'
import { Flex, Box, Heading, Button } from '../../../atoms'
import { ArticleCard } from '../../blog/commons'

export interface BlogProps extends SectionBaseProps {
  data: SectionBlog
}

const Blog: React.FC<BlogProps> = ({ data }) => {
  const { id, sectionId, headline, button, useLatest, articles } = data

  const articleList: ArticlePreviewWithAuthorAndCategoriesProps[] = useLatest ? lastBlogPostsQuery() : articles

  return (
    <Box as={'section'} id={sectionId || id} className={'p-section'} my={[80, 80, 120]}>
      <Grid>
        <Row>
          <Col xs={12}>
            <Flex justifyContent={'space-between'} alignItems={'center'}>
              <Box>
                <Heading as={'h3'}>{headline}</Heading>
              </Box>
              {button && (
                <Box ml={4}>
                  <Button {...button} />
                </Box>
              )}
            </Flex>
          </Col>
        </Row>
        <Box mt={8}>
          <Row>
            {articleList.map((a, i) => (
              <Col key={a.id} xs={12} sm={4}>
                <Box mb={i + 1 < articleList.length ? [9, 0] : undefined}>
                  <ArticleCard data={a} />
                </Box>
              </Col>
            ))}
          </Row>
        </Box>
      </Grid>
    </Box>
  )
}
export default Blog
