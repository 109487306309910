import React from 'react'
import { Text, TextProps } from '../../../../atoms'
import styled from 'styled-components'
import { mediaQueries } from '../../../../theme'

export const ArticleCardTitle: React.FC<TextProps> = styled(Text)<TextProps>`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;

  ${mediaQueries.greaterThan('sm')`
    -webkit-line-clamp: 3;
  `}
`

export const ArticleCardExcerpt: React.FC<TextProps> = styled(Text)<TextProps>`
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
`
