import { useStaticQuery, graphql } from 'gatsby'
// Types
import { ArticlePreviewWithAuthorAndCategoriesProps } from '../../../types/blog'

interface LastBlogPostsQuery {
  latestArticles: {
    nodes: ArticlePreviewWithAuthorAndCategoriesProps[]
  }
}

export const lastBlogPostsQuery = (): ArticlePreviewWithAuthorAndCategoriesProps[] => {
  const {
    latestArticles: { nodes },
  } = useStaticQuery<LastBlogPostsQuery>(graphql`
    query {
      latestArticles: allDatoCmsArticle(limit: 3, sort: { order: DESC, fields: meta___firstPublishedAt }) {
        nodes {
          ...articlePreviewWithAuthorAndCategoriesFragment
        }
      }
    }
  `)

  return nodes
}
