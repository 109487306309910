import React from 'react'
// Types
import { ArticlePreviewWithAuthorAndCategoriesProps } from '../../../../types/blog'
// Components
import { Link } from 'gatsby'
import { Row, Col } from 'react-styled-flexboxgrid'
import { Box, Text, Image } from '../../../../atoms'
import { ArticleCardTitle, ArticleCardExcerpt } from './ArticleCardFull.styled'
import ArticleAuthorsDate from '../ArticleAuthorsDate'

interface ArticleCardProps {
  data: ArticlePreviewWithAuthorAndCategoriesProps
}

const ArticleCardFull: React.FC<ArticleCardProps> = ({ data }) => {
  const { slug, title, excerpt, thumbnail, metaFormatted, authors, categories } = data

  return (
    <Box as={'article'}>
      <Row>
        <Col xs={12} sm={12} md={7}>
          <Box borderRadius={'regular'} overflow={'hidden'} zIndex={1}>
            <Link to={`/${slug}`} style={{ display: 'block' }}>
              <Image
                draggable={false}
                alt={thumbnail.alt || title}
                image={thumbnail.gatsbyImageData}
                style={{ borderRadius: '16px', overflow: 'hidden' }}
                imgStyle={{ borderRadius: '16px' }}
              />
            </Link>
          </Box>
        </Col>
        <Col xs={12} sm={12} md={5}>
          <Box px={[0, 0, 4]} mt={[5, 5, 4]} mb={[0, 0, 4]}>
            {categories && categories.length > 0 && (
              <Box mb={2}>
                {categories.map((c, i) => (
                  <Link key={c.id} to={`/${c.pageInfo.slug}`}>
                    <Text
                      as={'span'}
                      color={'purpleBlue'}
                      fontSize={13}
                      fontWeight={'semi'}
                      textTransform={'uppercase'}
                    >
                      {c.name}
                    </Text>
                    {i + 1 < categories.length && (
                      <Box display={'inline'} mx={1}>
                        <Text as={'span'} fontSize={13}>
                          {'•'}
                        </Text>
                      </Box>
                    )}
                  </Link>
                ))}
              </Box>
            )}
            <Link to={`/${slug}`}>
              <Box mb={2} minHeight={54}>
                <ArticleCardTitle fontSize={[18, 32, 40]} fontWeight={'semi'} lineHeight={[1.5, 1.2, 1.2]}>
                  {title}
                </ArticleCardTitle>
              </Box>
              <Box>
                <ArticleCardExcerpt fontSize={2} lineHeight={'28px'}>
                  {excerpt}
                </ArticleCardExcerpt>
              </Box>
            </Link>
            <Box mt={6}>
              <ArticleAuthorsDate
                small
                authors={authors}
                date={metaFormatted.firstPublishedAt || metaFormatted.createdAt}
              />
            </Box>
          </Box>
        </Col>
      </Row>
    </Box>
  )
}

export default ArticleCardFull
