import React from 'react'
// Types
import { AuthorProps } from '../../../../types/blog'
//Components
import { Link } from 'gatsby'
import { Box, Flex, Image, Text } from '../../../../atoms'

interface ArticleAuthorsDateProps {
  authors: AuthorProps[]
  date: string
  small?: boolean
  light?: boolean
}

const ArticleAuthorsDate: React.FC<ArticleAuthorsDateProps> = ({ authors, date, small = true, light = false }) => {
  return (
    <Flex alignItems={'center'}>
      <Flex alignItems={'center'} flex={'0 0 auto'} mr={3}>
        <Flex
          justifyContent={'center'}
          alignItems={'center'}
          width={small ? 48 : [48, 48, 56]}
          height={small ? 48 : [48, 48, 56]}
          borderRadius={'circle'}
          overflow={'hidden'}
          bg={!authors[0].profilePicture ? 'dark' : undefined}
          zIndex={3}
        >
          {authors[0].profilePicture ? (
            <Image
              draggable={false}
              alt={authors[0].profilePicture.alt || authors[0].name}
              image={authors[0].profilePicture.gatsbyImageData}
              style={{ width: '100%', height: '100%', objectFit: 'cover', overflow: 'hidden', borderRadius: '9999px' }}
              imgStyle={{ borderRadius: '9999px' }}
            />
          ) : (
            <Box position={'relative'} width={'100%'} height={'100%'} overflow={'hidden'}>
              <Box
                position={'absolute'}
                width={16}
                height={16}
                top={'9px'}
                left={'50%'}
                borderRadius={'circle'}
                bg={'white'}
                opacity={0.32}
                style={{
                  transform: 'translateX(-50%)',
                }}
              />
              <Box
                position={'absolute'}
                width={38}
                height={48}
                top={'30px'}
                left={'50%'}
                borderRadius={'circle'}
                bg={'white'}
                opacity={0.32}
                style={{
                  transform: 'translateX(-50%)',
                }}
              />
            </Box>
          )}
        </Flex>
        {authors.length === 2 && (
          <Flex
            ml={'-16px'}
            justifyContent={'center'}
            alignItems={'center'}
            width={small ? 48 : [48, 48, 56]}
            height={small ? 48 : [48, 48, 56]}
            borderRadius={'circle'}
            overflow={'hidden'}
            bg={!authors[1].profilePicture ? 'dark' : undefined}
            zIndex={2}
          >
            {authors[1].profilePicture ? (
              <Image
                draggable={false}
                alt={authors[1].profilePicture.alt || authors[1].name}
                image={authors[1].profilePicture.gatsbyImageData}
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                  overflow: 'hidden',
                  borderRadius: '9999px',
                }}
                imgStyle={{ borderRadius: '9999px' }}
              />
            ) : (
              <Box position={'relative'} width={'100%'} height={'100%'} overflow={'hidden'}>
                <Box
                  position={'absolute'}
                  width={16}
                  height={16}
                  top={'9px'}
                  left={'50%'}
                  borderRadius={'circle'}
                  bg={'white'}
                  opacity={0.32}
                  style={{
                    transform: 'translateX(-50%)',
                  }}
                />
                <Box
                  position={'absolute'}
                  width={38}
                  height={48}
                  top={'30px'}
                  left={'50%'}
                  borderRadius={'circle'}
                  bg={'white'}
                  opacity={0.32}
                  style={{
                    transform: 'translateX(-50%)',
                  }}
                />
              </Box>
            )}
          </Flex>
        )}
        {authors.length > 2 && (
          <Flex
            ml={'-16px'}
            pl={1}
            justifyContent={'center'}
            alignItems={'center'}
            width={small ? 48 : [48, 48, 56]}
            height={small ? 48 : [48, 48, 56]}
            borderRadius={'circle'}
            overflow={'hidden'}
            bg={'purpleBlue'}
            zIndex={1}
          >
            <Text fontSize={2} fontWeight={'semi'} color={'white'}>
              {authors.length - 1}
            </Text>
          </Flex>
        )}
      </Flex>
      <Box flex={'1'}>
        <Text fontSize={1} lineHeight={1.4} color={light ? 'white' : 'dark'}>
          {authors.length > 1 ? (
            <Text
              as={'span'}
              fontSize={'inherit'}
              fontWeight={'semi'}
              lineHeight={'inherit'}
              color={light ? 'white' : 'dark'}
            >
              {`${authors.length} Authors`}
            </Text>
          ) : (
            <Link to={`/${authors[0].pageInfo.slug}`}>
              <Text
                as={'span'}
                fontSize={'inherit'}
                fontWeight={'semi'}
                lineHeight={'inherit'}
                color={light ? 'white' : 'dark'}
              >
                {authors[0].name}
              </Text>
            </Link>
          )}
          <Text as={'span'} fontSize={'inherit'} lineHeight={'inherit'} opacity={0.48} color={'inherit'}>
            {' • '}
          </Text>
          <Text as={'span'} fontSize={'inherit'} lineHeight={'inherit'} color={'inherit'}>
            {date}
          </Text>
        </Text>
      </Box>
    </Flex>
  )
}
export default ArticleAuthorsDate
